import { Link } from "gatsby";
import * as React from "react";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import SidebarMenu from "./SidebarMenu/SidebarMenu";
import { Segment, Icon, Container, Sidebar, Header, Grid, Divider, List } from "semantic-ui-react";
import "../css/styles.css";
import "semantic-ui-less/semantic.less";
import { store } from "../store";
import { useState } from "react";

export const menuItems = [
  { name: "Job Board", path: "/", exact: true, icon: "home" },
  { name: "About", path: "/about/", exact: true, icon: "info circle" },
  { name: "Submit a Job", path: "/submit/", exact: true, icon: "info circle" },
  { name: "Contact", path: "/contact/", exact: true, icon: "info circle" },
];

export interface LayoutProps {
  location: {
    pathname: string;
  };
  children: any;
}

const Layout = (props: LayoutProps) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const { pathname } = props.location;
  return (
    <Sidebar.Pushable as={Segment}>
      <SidebarMenu Link={Link} pathname={pathname} items={menuItems} visible={sidebarVisible} />

      <Sidebar.Pusher style={{ minHeight: "100vh" }}>

        {/* Header */}
        <HeaderMenu
          Link={Link}
          pathname={pathname}
          items={menuItems}
          onToggleSidebar={() => setSidebarVisible(!sidebarVisible)}
        />

        {/* Render children pages */}
        <div style={{minHeight: "72vh"}}>
          {props.children}
        </div>

        {/* Footer */}
        <Segment inverted vertical style={{ padding: "5em 0em" }}>
          <Container>
            <Grid divided inverted stackable>
              <Grid.Row>
                <Grid.Column width={3}>
                  <Header inverted as="h4" content="About" />
                  <List link inverted>
                    <List.Item as={Link} to={"/about/"}>About</List.Item>
                    <List.Item as={Link} to={"/contact/"}>Contact</List.Item>
                    <List.Item as={Link} to={"/submit/"}>Submit a Job</List.Item>
                    <List.Item as={Link} to={"/privacy/"}>Privacy</List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={11}>
                  <Header as="h4" inverted>
                    getmeoutof.tech
                  </Header>
                  <p>
                    Job listings for technologists who don't want to work in venture capital-backed tech anymore.
                  </p>
                  <p>
                    Copyright © 2020 Jason Prado
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

export default Layout;

export const withLayout = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  class WithLayout extends React.Component<P & LayoutProps> {
    render() {
      return (
        <Layout location={this.props.location}>
          <WrappedComponent {...this.props} />
        </Layout>
      );
    }
  };
