import * as React from "react";
import { toggleSidebar } from "../../store";
import { Container, Menu, Icon } from "semantic-ui-react";
import { MenuProps } from "../Menu";

interface HeaderMenuProps extends MenuProps {
  inverted?: boolean;
  onToggleSidebar?: () => void;
}

export const HeaderMenu = ({ items, pathname, Link, onToggleSidebar}: HeaderMenuProps) =>
  <Container>
    <Menu size="large" pointing secondary>
      <Menu.Item as="a" className="mobile only" icon="sidebar" onClick={() => onToggleSidebar()} />
      <Menu.Item><Link to="/" style={{color: "black"}}>getmeoutof.tech</Link></Menu.Item>
      {items.map((item) => {
        const active = (item.exact) ? pathname === item.path : pathname.startsWith(item.path);
        return <Menu.Item
          as={Link}
          className="mobile hidden"
          name={item.name}
          to={item.path}
          key={item.path}
          active={active}
        />;
      })}
    </Menu>
  </Container>;

export default HeaderMenu;
